<template>
  <ResponsiveImage
    v-if="image !== null"
    :base-urls="baseUrls"
    :file-id="image.attributes.fileId"
    :file-name="image.attributes.fileName"
    :width="width"
    :height="height"
    :mobile-width="mobileWidth"
    :mobile-height="mobileHeight"
    :mode="mode"
    :lazy="lazy"
    :alt="alt"
    :title="title"
    class="spunq-image"
  >
    <slot />
  </ResponsiveImage>
</template>

<script>
import ResponsiveImage from './responsive-image'

export default {
  name: 'SpunqImage',
  components: {
    ResponsiveImage,
  },
  props: {
    lazy: {
      type: Boolean,
      default: false,
    },
    image: {
      /**
       * Image
       * {
       *    id
       *    type
       *    attributes {
       *      fileId
       *      fileName
       *      title
       *      description
       *      copyright
       *      frontendDate
       *    }
       * }
       */
      type: Object,
      required: true,
      // TODO: Schema-Validation
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    mobileWidth: {
      type: Number,
      default() {
        return this.width
      },
    },
    mobileHeight: {
      type: Number,
      default() {
        return this.height
      },
    },
    mode: {
      type: String,
      default: null,
    },
  },
  computed: {
    baseUrls() {
      return this.$config.imageServiceBaseUrls
    },
    alt() {
      const title = this.image.attributes.title || ''
      const desc = this.image.attributes.description || ''
      const copy = this.image.attributes.copyright ? `© ${this.image.attributes.copyright}` : ''
      return [title, desc, copy].filter((s) => !!s).join(' - ')
    },
    title() {
      return this.image.attributes.title || ''
    },
  },
}
</script>

<style lang="scss"></style>
