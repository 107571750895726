import { makeUrl } from '@/utils/image-url'

export function generateTitle(title, pageTitle) {
  return `${title} | ${pageTitle}`
}

export function generateMetaTags(
  pageTitle,
  baseUrl,
  imageServiceBaseUrls,
  title,
  description,
  url,
  image,
  imageWidth,
  imageHeight
) {
  return [
    // hid is used as unique identifier. Do not use `vmid` for it as it will not work
    {
      hid: 'description',
      name: 'description',
      content: description,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: generateTitle(title, pageTitle),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: title,
    },
    {
      hid: 'og:description',
      name: 'og:description',
      property: 'og:description',
      content: description,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: baseUrl + url,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content:
        image && image.attributes
          ? makeUrl(
              imageServiceBaseUrls,
              image.attributes.fileId,
              image.attributes.fileName,
              imageWidth,
              imageHeight,
              'c'
            )
          : image,
    },
    {
      hid: 'og:image:width',
      property: 'og:image:width',
      content: imageWidth,
    },
    {
      hid: 'og:image:height',
      property: 'og:image:height',
      content: imageHeight,
    },
  ]
}
