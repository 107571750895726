<template>
  <figure v-if="!!fileName" class="responsive-image">
    <picture v-if="lazy">
      <source
        ref="sourceMobile"
        :data-srcset="urlMobile"
        :srcset="mobilePlaceholder"
        :media="`(max-width: ${maxWidth}px)`"
      />
      <source ref="source" :data-srcset="url" :srcset="placeholder" :media="`(min-width: ${minWidth}px)`" />
      <img
        ref="image"
        :alt="alt || ''"
        :title="title || ''"
        :data-src="url"
        :src="placeholder"
        :class="loading ? 'lazyloading' : 'lazyloaded'"
      />
    </picture>
    <picture v-else>
      <source :srcset="urlMobile" :media="`(max-width: ${maxWidth}px)`" />
      <source :srcset="url" :media="`(min-width: ${minWidth}px)`" />
      <img :alt="alt || ''" :title="title || ''" :src="url" />
    </picture>
    <slot />
  </figure>
</template>

<script>
/**
 * From: https://markus.oberlehner.net/blog/lazy-loading-responsive-images-with-vue/
 */

import lozad from 'lozad'

export default {
  name: 'ResponsiveImage',
  props: {
    baseUrls: {
      type: Array,
      required: true,
      validator(value) {
        return value && value.length > 0
      },
    },
    fileName: {
      type: String,
      required: true,
    },
    fileId: {
      type: String,
      default() {
        // Fallback-id generated from filename
        return this.fileName
          .split('')
          .map((c) => c.charCodeAt(0) - 64)
          .reduce((v, r) => v + r, 0)
      },
    },
    title: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    mobileWidth: {
      type: Number,
      default() {
        return this.width
      },
    },
    mobileHeight: {
      type: Number,
      default() {
        return this.height
      },
    },
    minWidth: {
      type: Number,
      default() {
        return typeof this.maxWidth === 'number' ? this.maxWidth + 1 : 768
      },
    },
    maxWidth: {
      type: Number,
      default() {
        return typeof this.minWidth === 'number' ? this.minWidth - 1 : 767
      },
    },
    mode: {
      type: String,
      default: null,
      validator(value) {
        /**
         * Define allowed modes
         */
        const modes = [null, 'crop']
        return modes.includes(value)
      },
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    baseUrl() {
      return this.baseUrls[this.fileId % this.baseUrls.length]
    },
    url() {
      return this.makeUrl()
    },
    urlMobile() {
      return this.makeUrl(this.mobileWidth, this.mobileHeight)
    },
    placeholder() {
      const width = Math.round(this.width / 10)
      const height = Math.round(this.height / 10)
      return this.makeUrl(width, height)
    },
    mobilePlaceholder() {
      const width = Math.round(this.mobileWidth / 10)
      const height = Math.round(this.mobileHeight / 10)
      return this.makeUrl(width, height)
    },
    transformationMode() {
      switch (this.mode) {
        case 'crop':
          return 'c'
        default:
          return ''
      }
    },
  },
  mounted() {
    if (!this.lazy) {
      this.loading = false
      return
    }
    // As soon as the <img> element triggers
    // the `load` event, the loading state is
    // set to `false`, which removes the apsect
    // ratio we've applied earlier.
    // Change: Since we use a placeholder we need to check
    // for the lodaz callback
    const setLoadingState = () => {
      this.loading = !this.$refs.image.getAttribute('data-loaded')
    }

    const imageElement = this.$refs.image
    imageElement.addEventListener('load', setLoadingState)
    // We remove the event listener as soon as
    // the component is destroyed to prevent
    // potential memory leaks.
    this.$once('hook:destroyed', () => {
      imageElement.removeEventListener('load', setLoadingState)
    })

    // We initialize Lozad.js on each element individualy
    // since the lodaz picture functionality doesn't work with vue
    lozad(this.$refs.image).observe()
    lozad(this.$refs.source).observe()
    lozad(this.$refs.sourceMobile).observe()
  },
  methods: {
    /**
     * Generate URL for image service
     * @param {Number} width - Transformation width, defaults to this.width
     * @param {Number} height - Transformation height, defaults to this.height
     * @param {String} mode - Transformation modes crop ['c', 's', 'cN', ...]
     * @param {String} format - output format (jpg, png) - not implemented.
     */
    makeUrl(width, height, mode, format) {
      width = width || this.width
      height = height || this.height
      mode = mode || this.transformationMode
      const fileName = encodeURIComponent(this.fileName)
      return `${this.baseUrl}/${this.fileId}-${width}x${height}${mode || ''}-${fileName}`
    },
  },
}
</script>

<style lang="scss">
.responsive-image {
  width: 100%;

  img {
    width: 100%;
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    transition: filter 0.7s, transform 0.7s;
  }
}

.lazyloading {
  filter: blur(10px);
}
</style>
